<template>
	<div class="detail-mobile" v-if="detailItem&&isMobile">
		<div class="column-me row-center">
			<div class="title">
				{{detailItem.title}}
			</div>
			<div class="column-me row-center margin-top40">
				<div class="subtitle">发表时间：{{detailItem.create_time}}</div>
				<div class="subtitle margin-top20">点击次数：{{detailItem.click_num}}</div>
			</div>
			<div class="line"></div>
			<div class="content" v-html="detailItem.content"></div>
		</div>
	</div>
	<div class="detail" v-if="detailItem&&!isMobile">
		<div class="column-me row-center">
			<div class="title">
				{{detailItem.title}}
			</div>
			<div class="row-me row-center margin-top40">
				<div class="subtitle">发表时间：{{detailItem.create_time}}</div>
				<div class="subtitle margin-left50">点击次数：{{detailItem.click_num}}</div>
			</div>
			<div class="line"></div>
			<div class="content" v-html="detailItem.content"></div>
		</div>
	</div>
</template>

<script>
	export default {
		components: {

		},
		data() {
			return {
				detailItem: null,
				isMobile: false,
			}
		},
		// html加载完成之前
		created() {
			this.id = this.$route.query.id
			this.getData()
		},
		// html加载完成后执行。
		mounted() {
			this.isMobile = sessionStorage.getItem('mobile') === '1'
		},
		// 事件方法执行
		methods: {
			getData() {
				this.$postRequest('/api/index/getNewsInfo', {
					id: this.id
				}).then((res) => {
					if (res) {
						this.detailItem = res.data
					}
				}).catch((error) => {
					console.log(error)
				})
			}
		},
		// 计算属性
		computed: {

		},
		// 侦听器
		watch: {

		},
	}
</script>

<style lang="scss" scoped>
	.detail-mobile {
		padding: 50px 20px;

		.title {
			font-size: 33px;
			color: #000000;
			text-align: center;
		}

		.subtitle {
			font-size: 20px;
			color: #666666;
			font-weight: 300;
		}

		.line {
			width: 100%;
			height: 1px;
			background: #999999;
			margin-top: 30px;
		}

		.content {
			width: 100%;
			font-size: 22px;
			font-weight: 300;
			color: #333333;
			line-height: 30px;
			padding-top: 10px;

			/deep/ img {
				max-width: 100%;
				object-fit: scale-down;
				display: block;
				margin: auto;
			}

			/deep/ p {
				margin: 0 !important;
			}
		}
	}

	.detail {
		padding: 100px;

		.title {
			font-size: 48px;
			color: #000000;
			text-align: center;
		}

		.subtitle {
			font-size: 24px;
			color: #666666;
			font-weight: 300;
		}

		.line {
			width: 100%;
			height: 1px;
			background: #999999;
			margin-top: 60px;
		}

		.content {
			width: 100%;
			font-size: 24px;
			font-weight: 300;
			color: #333333;
			line-height: 40px;
			padding-top: 50px;

			/deep/ img {
				max-width: 100%;
				object-fit: scale-down;
				display: block;
				margin: auto;
			}

			/deep/ p {
				margin: 0 !important;
			}
		}
	}
</style>
